"use client";
import React, { useState, useEffect } from "react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "@lib/utils";
import { useCookies } from "next-client-cookies";
import { classNames } from "@lib/helpers";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import Loading from "@components/Loading/Loading";

interface SearchProps {
  placeholder?: string;
  className?: string;
}

const NavSearch = ({ placeholder, className }: SearchProps) => {
  const cookies = useCookies();
  const isArabic = cookies.get("lang") === "ar";
  const [showOptions, setShowOptions] = useState(false);
  const [searchType, setSearchType] = useState<"Jobs" | "Companies">("Jobs");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Reset loading state when search results are displayed
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [pathname, searchParams]);

  const toggleOptions = () => setShowOptions((prev) => !prev);

  const selectOption = (type: "Jobs" | "Companies") => {
    setSearchType(type);
    setShowOptions(false);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const query = searchTerm.trim() ? `&search=${searchTerm}` : "";
    router.push(`/${searchType.toLowerCase()}?page=1${query}`);
  };

  const handleCancel = () => {
    setSearchTerm("");
    setSearchType("Jobs");
    setShowOptions(false);
    router.push(`/${searchType.toLowerCase()}?page=1`);
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <div className="flex items-center relative z-10">
          <input
            type="text"
            placeholder={`${placeholder || "Search"} (${searchType})`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={cn(
              "placeholder-[#FFFFFF80] w-[303px] h-[44px] border-none outline-none rounded-[8px] bg-[#FFFFFF0F] p-[6px] pl-[14px] text-[14px] text-[#fff]",
              className
            )}
          />
          
          <div
            className={classNames(
              "absolute flex items-center gap-2",
              isArabic ? "left-2 top-2 bottom-2" : "right-2 top-2 bottom-2"
            )}
          >
            {searchTerm && (
              <button
                type="button"
                onClick={handleCancel}
                className="inline-block p-1"
              >
                <XMarkIcon className="text-[#fff] w-6" />
              </button>
            )}
            <div className="relative">
              <button
                type="button"
                onClick={toggleOptions}
                className="pl-[8px] pr-[8px] w-[80px] h-[32px] bg-[#FFFFFF1A] rounded-[4px] flex items-center justify-between gap-[5px] text-[#fff] text-[12px]"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex justify-center w-full">
                    <Loading className="w-9 h-9 text-[#F59A00]" />
                  </div>
                ) : (
                  <>
                    {searchType}
                    <ChevronDownIcon className="text-[#fff] w-[14px] h-[14px]" />
                  </>
                )}
              </button>
              {showOptions && !isLoading && (
                <div
                  className="absolute top-full mt-1 bg-[#0C1521] rounded-[4px] overflow-hidden transform scale-y-100 transition-transform duration-300 ease-in-out"
                  style={{ transformOrigin: "top" }}
                >
                  {["Jobs", "Companies"].map((option) => (
                    <div
                      key={option}
                      onClick={() =>
                        selectOption(option as "Jobs" | "Companies")
                      }
                      className="cursor-pointer px-4 py-2 text-[#fff] text-[12px] hover:bg-[#2a3b4c]"
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NavSearch;