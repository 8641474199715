export const BYPASS_POST_VALIDATION_FOR_EMAILS = [
  "admin@wazifame.com",
  "info@wazifame.com",
];

export const FEEDBACK_TYPES = {
  general: "General Feedback",
  suggestion: "Suggestion",
  "bug-report": "Bug Report",
  jobPostFeedback: "job-post-feedback",
};

export const SUBSCRIPTION_DURATIONS = {
  1: "MONTHLY",
  6: "MONTHS6",
  12: "YEARLY",
};

export const SUBSCRIPTION_MONTHS = {
  MONTHLY: 1,
  MONTHS6: 6,
  YEARLY: 12,
};

export const silverSubscriptionPlanId: any = {
  1: 135,
  6: 136,
  12: 137,
};

export const goldSubscriptionPlanId: any = {
  1: 138,
  6: 139,
  12: 140,
};

export const diamondSubscriptionPlanId: any = {
  1: 1971,
  6: 1972,
};

export const APPLICATIONS_PER_PAGE = 20;

export const LANGUAGES = [
  {
    key: "English",
    value: "en",
  },
  {
    key: "Arabic",
    value: "ar",
  },
  {
    key: "French",
    value: "fr",
  },
];

export const DESCRIPTION_LIMIT = 30;
